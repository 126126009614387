globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"jA3lZSQqLw8nE_S0KX0wF"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

if (process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production') {
  Sentry.init({
    dsn: 'https://7f6632eaf13ac56513e7e2163de5f73a@o4506899762970625.ingest.us.sentry.io/4507747224715264',

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
    environment: `production-${process.env.NEXT_PUBLIC_LANGUAGE}`,
  })
}
